<template lang="pug">
.page
	Menu(:title="activeTabTitle")

	Tabs.my-30(:tabs="tabItems" type-tab="tabs" v-model="activeTab")
	transition(name="fade" mode="out-in")
		component(
			:is="activeTab"
			:key="activeTab"
			:date="date"
			:needUpdateResult="needUpdateResult"
			:isVisibleData="isVisibleData"
			:isFilterFull="isFilterFull"
			:stores="stores"
			:categories="categories"
			:brands="brands"
			:regions="regions"
			:dateFrom="dateFrom"
			:dateTo="dateTo"
			@showData="isVisibleData = true"
		)
</template>

<script>
import Menu from '@/components/Menu/Menu.vue'
import OverviewAndTrend from '@/components/Nestle/DynamicComponent/OverviewAndTrend'
import ShareOfTaxonomy from '@/components/Nestle/DynamicComponent/ShareOfTaxonomy'
import ProductDetails from '@/components/Nestle/DynamicComponent/ProductDetails'
import ProductFalls from '@/components/Nestle/DynamicComponent/ProductFalls'
import Tabs from "@/components/Nestle/Tabs"
import TabItem from '@/components/Tabs/TabItem.vue'
import NewTab from '@/components/Tabs/Tabs.vue'

export default {
	name: "TaxonomyPlacement",
	components: {
		Tabs,
		TabItem,
		Menu,
		OverviewAndTrend,
		ShareOfTaxonomy,
		ProductDetails,
		ProductFalls,
		NewTab,
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isFilterFull: {
			type: Boolean,
			default: false,
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			list: [],
			activeTab: 'OverviewAndTrend',
			resp: null,
			current: 1,
			limit: 20,
			offset: 0,
			isVisibleData: false,
		}
	},
	created() {
		this.useQuery()
	},
	computed:{
		tabItems(){
			return [
				{
					title: 'Overview & Trend',
					value: 'OverviewAndTrend'
				},
				{
					title: 'Share Of Taxonomy',
					value: 'ShareOfTaxonomy'
				},
				{
					title: 'Product Details',
					value: 'ProductDetails'
				},
				{
					title: 'Product Fails',
					value: 'ProductFalls'
				}
			]
		},
		activeTabTitle() {
			return this.tabItems.find(item => item.value === this.activeTab).title
		}
	},
	methods: {
		useQuery(){
			if(this.$route.query.tab){
				this.activeTab = this.$route.query.tab
			}
		},
	},
	watch: {
		activeTab: {
			handler(value){
				this.$router.push({query: { tab: value }}).catch(() => {})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.page {
	max-width: 1280px;
	margin: 0 auto;
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;

		&-icon {
			margin-right: 4px;
		}
	}
}
.disabled-chart{
	position: absolute;
	pointer-events: none;
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
	opacity: 0;
}
</style>
